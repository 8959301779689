import LoginButton from "../Components/LoginBtn";

const Login = () => {
    return (
        <div>
            <h1>Login</h1>
            <LoginButton />
        </div>
    );
}
export default Login;